import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from '../theme'
import Layout from '../global/Layout'
import { MsgStyled } from '../common/common'

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <MsgStyled>
        Not found: You just hit a route that doesn&#39;t exist... the sadness.
      </MsgStyled>
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage
